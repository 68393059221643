.container{
  margin-top: -10px;
}

#googleMapDiv {
  padding: 10px;
}

.autoComp > div:nth-of-type(1) {
  width: 100%;
}

.autoComp div {
  z-index: 10;
}

.autoComp div input{
  display: block;
  width: 100%;
  height: 25px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;  
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.has-error div input{
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.autoComp div input:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}

.form-control {
  height: 25px;
}

.form-control-margin2 {
  height: 25px;
  margin-top: 2px;
}

.form-group {
  margin-bottom: 4px;
}

.checkbox {
  margin-top: 25px;
  margin-bottom: 0px;
}

.checkbox input {
  margin-top: 1px;
}

.markers {
  position: absolute;
  left: -11.5px;
  top: -18.5px;
}

.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--multiple{
  border: 1px solid #ccc;
}

.has-error .select2-container--default .select2-selection--multiple{
  border-color: #a94442;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}

label {
  margin-bottom: 0;
}

button[disabled], html input[disabled] {
  cursor: not-allowed;
  background-color: #eee;
  opacity: 1;
}

.btn-toolbar {
  margin-left: 10px;
}

textarea{
  height: 55px !important;
}

.pricesButton {
  margin-top: 15px;
}

.hidden {
  display: none;
}

.enderecos-antigos {
  border-bottom: 1px solid #EBEBEB;
  padding: 15px;
}

.titulo-antigos {
	border-bottom: 1px solid;
  text-align: center;
  margin: 0;
}

.enderecos-antigos div div {
  text-align: center;
}

.enderecos-antigos:hover {
  background: #FFFFD6 !important;
}

.enderecos-antigos:focus {
  background:  rgb(224, 224, 224);
}

.react-autosuggest__suggestions-container--open {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
    background: rgba(255, 255, 255, 1) none repeat scroll 0% 0%;
    padding: 2px 0px;
    font-size: 90%;
    position: absolute;
    width: 200%;
}

.react-datepicker-popper {
  z-index: 10;
}

.search .react-autosuggest__suggestions-container--open {
  width: 95%;
}

.react-autosuggest__suggestion--highlighted {
    background: lightgray none repeat scroll 0% 0%;
}

.react-autosuggest__suggestion {
	border-bottom: 1px solid lightgrey;
}

.react-autosuggest__suggestions-list {
	padding: 0;
	list-style-type: none;
}

.agend-dialog-modal {
  width: 900px;
}

.historico-dialog-modal {
  width: 101%;
  margin: 0;
  padding: 0;
  margin-left: -10px;
}

.corridaz-dialog-modal {
  width: 100%;
  margin: 0;
  padding: 0;
}

.referencias-dialog-modal {
  width: 800px;
}

.corridaz-dialog-modal .modal-content .modal-body {
  padding: 0px !important;
}

.dados-corrida-modal {
  width: 100%;
  margin: 0;
  padding: 0;
}

.cadastros-modal {
  width: 70%;
  margin: auto;
  padding: 10;
}

.historico-despacho-modal {
  width: 70%;
}

.pesquisa-corridas-dialog-modal {
  width: 87%;
}

.box-status {
  color:#fff
}

.minha-coluna-2percent{
  width: 2% !important;
}

.minha-coluna-4percent{
  width: 4% !important;
}

.minha-coluna-6percent{
  width: 6% !important;
}

.minha-coluna-7percent{
  width: 7% !important;
}

.minha-coluna-9percent{
  width: 9% !important;
}

.minha-coluna-12percent{
  width: 12% !important;
}

.minha-coluna-18percent{
  width: 18% !important;
}

.select2-results__group{
  background: lightgray;
}

.rule-title {
	text-align: center;
	font-size: 17px;
	color: whitesmoke;
	border-bottom: 1px black solid;
	margin-top: -7px;
}

.select2-selection__choice__remove{
  display: none !important;
}

.react-table-date-picker {
  width: 7.8vw;
  border: 1px solid rgba(0,0,0,0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}