.chat
{
    list-style: none;
    margin: 0;
    padding: 0;
}

.shadow{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6) !important;
  border-right: 1px solid ; 
}

.title_border{
  border-bottom:1px solid black ; 

}
.cartoes
{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  margin-left: 1px;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-right: 8px;
  padding: 3px 10px 3px 10px;
  background-color: #FEFEFE;
}
.chat li
{
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dotted #B3A9A9;
}

.chat li.left .chat-body
{
    margin-left: 60px;
}

.chat li.right .chat-body
{
    margin-right: 60px;
}


.chat li .chat-body p
{
    margin: 0;
    color: #777777;
}

.panel .slidedown .glyphicon, .chat .glyphicon
{
    margin-right: 5px;
}

.chat-wrapper .sc-launcher
{
  right: inherit;
  z-index: 99;
}

.chat-wrapper .sc-user-input--text:empty:before {
  color: rgba(86, 88, 103, 0.8);
}

.chat-wrapper .sc-user-input.active{
  -webkit-box-shadow: 0px -5px 20px 0px rgba(119, 128, 143, 0.8);
  box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.8);
}

.chat-wrapper .sc-user-input--text{
  color: #3b3c42;

}
.chat-wrapper .sc-launcher .sc-open-icon{
  right: inherit;
  z-index: 99;
}
.chat-wrapper .sc-launcher .sc-closed-icon {

  right: inherit;
  z-index: 99;
}

.chat-wrapper .sc-header{
  z-index: 99;
}

.chat-wrapper .sc-message-list{
  z-index: 99;
}

.chat-wrapper .sc-user-input{
  z-index: 99;
}
.chat-wrapper .sc-chat-window{
  right: inherit;
  z-index: 99;}

.panel-body
{
    overflow-y: scroll;
    height: 250px;
}

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

.rastreamento-dialog-modal {
  width: 70%;
}

.pa-dialog-modal {
  width: 50%;
}

.mensagens-dialog-modal .modal-content {
  background-color: transparent;
  border: inherit;
  box-shadow: inherit;
}

.sc-user-input--file-icon-wrapper {
  display: none;
}