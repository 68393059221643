html {
    font-size: 14px;
  }
  .react-datepicker__time-list{
    -webkit-padding-start: 0px;
    padding-left: 0px;
  }
  .remove{
    height: 100%;
    overflow-x: hidden;
  }

  @media print {
    .col-md-3{
      margin: 45px 50px 0px 50px ;
    }
    .col-md-9{
      margin: 0px 50px 0px 41px ;
    }
  }

  .open>.dropdown-menu {
    display: block;
    width: max-content;
    min-width: fit-content;
  }
/* .block-ui-overlay {
  background-color: #e7e7e7 !important;
} */