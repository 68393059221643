.pagamentoOK {
	color: #ffffff;
	font-weight: bold;
	background: #d8030b  !important;
}
.pagamentoOK:hover {
	color: #ffffff;
	ont-weight: bold;
	background: #AA4343 !important;
}
.pagamentoNOK {
	color: #000000;
	background: #CCCCCC  !important;
}
.pagamentoNOK:hover {
	color: #000000;
	background: #AAAAAA !important;
}