.example-enter {
    opacity: 0.01;
  }
  
  .example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  
  .example-leave {
    opacity: 1;
  }
  
  .example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

  .espaco{
    border-spacing: 5px 5px;
    border-collapse: separate;
    border: none;
  }

  .vai{
    border: none !important;
  }
  .cartaomonitor{
    background-color: #F5F5F5 ;
    border:1px solid black ; 
    margin-left:5px;
    border-radius: 5px;
    height: 100;
    width: 200;
    overflow: hidden;
    -webkit-transform: translateX(0px);
    -webkit-transition-duration:0.3s;
    transition-duration: 0.3s;
    transform: translateX(0px);
  }
  .cartao{
    background-color: #F5F5F5 ;
    border:1px solid black ; 
    margin-left:5px;
    border-radius: 5px;
    -webkit-transform: translateX(0px);
    -webkit-transition-duration:0.3s;
    transition-duration: 0.3s;
    transform: translateX(0px);
  }

  .card-body {
    padding-left: 5px;
  }

  .dados-corrida{
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .card-footer {
    border-top: 1px solid white;
  }

  .cartaomonitor:hover
  {
        box-shadow:
                  1px 1px #b3b3b3,
                  2px 2px #b3b3b3,
                  3px 3px #b3b3b3;
          -webkit-transform: translateX(-1px);
          -webkit-transition-duration:0.3s;
          transition-duration: 0.3s;
          transform: translateX(-1px);
  }
  
  .cartao:hover
{
      box-shadow:
                1px 1px #b3b3b3,
                2px 2px #b3b3b3,
                3px 3px #b3b3b3;
        -webkit-transform: translateX(-1px);
        -webkit-transition-duration:0.3s;
        transition-duration: 0.3s;
        transform: translateX(-1px);
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #838383;
  color: white;
  text-align: center;
}
.coluna{
  
   border-right: 1px solid black;
}

.alinhamento{
  margin-left:15px;
}
.espacamento{
  margin:10px;
}
.clicado:focus{
    background: #777777;
}

.data-recla p {
  margin: 0;
}

.data-box {
  border: 1px solid black;
  border-radius: 10px;
  padding: 0px 0px 0px 10px;
  margin: 0 0 10px -5px;
}

.data-box p {
  margin: 0;
}

.data-box h4 {
  margin-bottom: 8px;
}

.dados-corrida-dialog {
  width: 80%;
}

.button-grid .btn {
  color: red;
  width: 100%;
  margin-bottom: 5px;
  height: 80px;
}

div.button-grid div:nth-child(2) {
  padding: 0;
}

.button-grid div {
  padding: 0px 5px 0px 5px;
}

.enderecos-antigos {
  border-bottom: 1px solid #EBEBEB;
  padding: 15px;
}

.titulo-antigos {
	border-bottom: 1px solid;
  text-align: center;
  margin: 0;
}

.enderecos-antigos div div {
  text-align: center;
}

@keyframes color {
  0% {
    background-color: #222;
  }
  50% {
    background-color: #4285f4;
  }
  100% {
    background-color: #222;
  }
}
/*Body Styles*/
.transicao {
    border:1px solid black ; 
    margin-left:5px;
    border-radius: 5px;
  background-color: #222;
  animation-name: color;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

/* .modal-open div:nth-child(2){
  padding-left: 0 !important;
} */