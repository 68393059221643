.example-enter {
    opacity: 0.01;
  }
  
  .example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  
  .example-leave {
    opacity: 1;
  }
  
  .example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

  .espaco{
    border-spacing: 5px 5px;
    border-collapse: separate;
    border: none;
  }

  .vai{
    border: none !important;
  }
  .cartaomonitor{
    background-color: #F5F5F5 ;
    border:1px solid black ; 
    margin-left:5px;
    border-radius: 5px;
    height: 100;
    width: 200;
    overflow: hidden;
    -webkit-transform: translateX(0px);
    transition-duration: 0.3s;
    transform: translateX(0px);
  }
  .cartao{
    background-color: #F5F5F5 ;
    border:1px solid black ; 
    margin-left:5px;
    border-radius: 5px;
    -webkit-transform: translateX(0px);
    transition-duration: 0.3s;
    transform: translateX(0px);
  }

  .card-body {
    padding-left: 5px;
  }

  .dados-corrida{
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .card-footer {
    border-top: 1px solid white;
  }

  .cartaomonitor:hover
  {
        box-shadow:
                  1px 1px #b3b3b3,
                  2px 2px #b3b3b3,
                  3px 3px #b3b3b3;
          -webkit-transform: translateX(-1px);
          transition-duration: 0.3s;
          transform: translateX(-1px);
  }
  
  .cartao:hover
{
      box-shadow:
                1px 1px #b3b3b3,
                2px 2px #b3b3b3,
                3px 3px #b3b3b3;
        -webkit-transform: translateX(-1px);
        transition-duration: 0.3s;
        transform: translateX(-1px);
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #838383;
  color: white;
  text-align: center;
}
.coluna{
  
   border-right: 1px solid black;
}

.alinhamento{
  margin-left:15px;
}
.espacamento{
  margin:10px;
}
.clicado:focus{
    background: #777777;
}

.data-recla p {
  margin: 0;
}

.data-box {
  border: 1px solid black;
  border-radius: 10px;
  padding: 0px 0px 0px 10px;
  margin: 0 0 10px -5px;
}

.data-box p {
  margin: 0;
}

.data-box h4 {
  margin-bottom: 8px;
}

.dados-corrida-dialog {
  width: 80%;
}

.button-grid .btn {
  color: red;
  width: 100%;
  margin-bottom: 5px;
  height: 80px;
}

div.button-grid div:nth-child(2) {
  padding: 0;
}

.button-grid div {
  padding: 0px 5px 0px 5px;
}

.enderecos-antigos {
  border-bottom: 1px solid #EBEBEB;
  padding: 15px;
}

.titulo-antigos {
	border-bottom: 1px solid;
  text-align: center;
  margin: 0;
}

.enderecos-antigos div div {
  text-align: center;
}

@-webkit-keyframes color {
  0% {
    background-color: #222;
  }
  50% {
    background-color: #4285f4;
  }
  100% {
    background-color: #222;
  }
}

@keyframes color {
  0% {
    background-color: #222;
  }
  50% {
    background-color: #4285f4;
  }
  100% {
    background-color: #222;
  }
}
/*Body Styles*/
.transicao {
    border:1px solid black ; 
    margin-left:5px;
    border-radius: 5px;
  background-color: #222;
  -webkit-animation-name: color;
          animation-name: color;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

/* .modal-open div:nth-child(2){
  padding-left: 0 !important;
} */
.sc-emoji-picker {
  overflow: auto;
  width: 100%;
  max-height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 15px;
}

.sc-emoji-picker--category {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sc-emoji-picker--category-title {
  min-width: 100%;
  color: #b8c3ca;
  font-weight: 200;
  font-size: 13px;
  margin: 5px;
  letter-spacing: 1px;
}

.sc-emoji-picker--emoji {
  margin: 5px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  font-size: 28px;
  transition: transform 60ms ease-out,-webkit-transform 60ms ease-out;
  transition-delay: 60ms;
}

.sc-emoji-picker--emoji:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}
.sc-chat-window {
  width: 370px;
  height: calc(100% - 120px);
  max-height: 590px;
  position: fixed;
  right: 25px;
  bottom: 100px;
  box-sizing: border-box;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sc-chat-window.closed {
  opacity: 0;
  visibility: hidden;
  bottom: 90px;
}

.sc-message-list {
  height: 80%;
  overflow-y: auto;
  background-color: white;
  background-size: 100%;
  padding: 40px 0px;
}

.sc-message--me {
  text-align: right;
}
.sc-message--them {
  text-align: left;
}

@media (max-width: 450px) {
  .sc-chat-window {
    width: 100%;
    height: 100%;
    max-height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
  }
  .sc-chat-window {
    transition: 0.1s ease-in-out;
  }
  .sc-chat-window.closed {
    bottom: 0px;
  }
}

.sc-launcher {
  width: 60px;
  height: 60px;
  
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  right: 25px;
  bottom: 25px;
  border-radius: 50%;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
}
.sc-launcher-blue {
  background-color: #4e8cff;
}
.sc-launcher-red {
  background-color: #ee3434;
}
.sc-launcher-green {
  background-color: #48e428;
}

.sc-launcher:before {
  content: '';
  position: relative;
  display: block;
  width: 60px;
  height: 60px;  
  border-radius: 50%;
  transition: box-shadow 0.2s ease-in-out;
}

.sc-launcher .sc-open-icon,
.sc-launcher .sc-closed-icon {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 25px;
  bottom: 25px;
  transition: opacity 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
}

.sc-launcher .sc-closed-icon {
  transition: opacity 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
  width: 60px;
  height: 60px;
}

.sc-launcher .sc-open-icon {
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
}

.sc-launcher.opened .sc-open-icon {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  opacity: 1;
}

.sc-launcher.opened .sc-closed-icon {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  opacity: 0;
}

.sc-launcher.opened:before {
  box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
}

.sc-launcher:hover {
  box-shadow: 0 0px 27px 1.5px rgba(0,0,0,0.2);
}

.sc-new-messages-count {
  position: absolute;
  top: -3px;
  left: 41px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
	width: 22px;
  height: 22px;
  background: #ff4646;
  color: white;
  text-align: center;
  margin: auto;
  font-size: 12px;
  font-weight: 500;
  box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.3);
}
.sc-header {
  background: #4e8cff;
  min-height: 75px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  color: white;
  padding: 10px;
  box-shadow: 0 1px 4px rgba(0,0,0,.2);
  position: relative;
  box-sizing: border-box;
  display: flex;
}
.sc-header-green {
  background: #48e428;
  min-height: 75px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  color: white;
  padding: 10px;
  box-shadow: 0 1px 4px rgba(0,0,0,.2);
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.sc-header--img {
  border-radius: 50%;
  align-self: center;
  padding: 10px;
}

.sc-header--team-name {
  align-self: center;
  padding: 10px;
  flex: 1 1;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 5px;
}

.sc-header--close-button {
  width: 40px;
  align-self: center;
  height: 40px;
  margin-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
}

.sc-header--close-button:hover {
  background: #4882ed;
}

.sc-header--close-button img {
  width: 100%;
  height: 100%;
  padding: 13px;
  box-sizing: border-box;
}

@media (max-width: 450px) {
  .sc-header {
    border-radius: 0px;
  }
}
.sc-message {
  width: 300px;
  margin: auto;
  padding-bottom: 10px;
  display: flex;
}

.sc-message--content {
  width: 100%;
  display: flex;
}

.sc-message--content.sent {
  justify-content: flex-end;
}

.sc-message--content.sent .sc-message--avatar {
  display: none;
}

.sc-message--avatar {
  background-image: url(https://d13yacurqjgara.cloudfront.net/assets/avatar-default-aa2eab7684294781f93bc99ad394a0eb3249c5768c21390163c9f55ea8ef83a4.gif);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 15px;
}

.sc-message--text {
  padding: 17px 20px;
  border-radius: 6px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  width: calc(100% - 90px);
}
.sc-message--system {
  padding: 17px 20px;
  border-radius: 6px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  width: calc(100% - 90px);
}
.sc-message--content.sent .sc-message--text {
  color: white;
  background-color: #4e8cff;
  max-width: calc(100% - 120px);
  word-wrap: break-word;
}

.sc-message--content.received .sc-message--text {
  color: #263238;
  background-color: #f4f7f9;
  margin-right: 40px;
}

.sc-message--emoji {
  font-size: 40px;
}

.sc-message--file {
  background: white;
  border: solid 1px #CCCDD1;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  text-decoration: none;
}

.sc-message--file p {
  margin: 0px 0px 0px 10px;
  color: rgba(86, 88, 103, 0.6);
}

.sc-message--file .sc-user-input--file-icon:hover path {
  fill: rgba(86, 88, 103, 0.3);
}

@media (max-width: 450px) {
  .sc-message {
    width: 80%;
  }
}
.sc-user-input {
  min-height: 55px;
  margin: 0px;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: #f4f7f9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color .2s ease,box-shadow .2s ease;
}


.sc-user-input--text {
  width: 300px;
  resize: none;
  border: none;
  outline: none;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
  padding: 18px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #565867;
  -webkit-font-smoothing: antialiased;
  max-height: 200px;
  overflow: scroll;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.sc-user-input--text:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: rgba(86, 88, 103, 0.3);
  outline: none;
}

.sc-user-input--buttons {
  width: 100px;
  position: absolute;
  right: 10px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.sc-user-input--button:first-of-type {
  width: 40px;
}

.sc-user-input--button {
  width: 30px;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sc-user-input--button button {
  cursor: pointer;
}

.sc-user-input--buttons input[type="file"] {
  display: none;
}

.sc-user-input--picker-wrapper {
  display: flex;
  flex-direction: column;
}

.sc-user-input.active {
  box-shadow: none;
  background-color: white;
  box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2);
}

.sc-user-input--file-icon,
.sc-user-input--send-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  align-self: center;
  outline: none;
}

.sc-user-input--file-icon path,
.sc-user-input--send-icon path {
  fill: rgba(86, 88, 103, 0.3);
}

.sc-user-input--file-icon:hover path,
.sc-user-input--send-icon:hover path {
  fill: rgba(86, 88, 103, 1);
}

.sc-user-input--emoji-icon-wrapper,
.sc-user-input--send-icon-wrapper,
.sc-user-input--file-icon-wrapper {
  background: none;
  border: none;
  padding: 2px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sc-user-input--send-icon-wrapper,
.sc-user-input--file-icon-wrapper {
  flex-direction: row;
}

.sc-user-input--emoji-icon-wrapper:focus {
  outline: none;
}

.sc-user-input--emoji-icon {
  height: 18px;
  cursor: pointer;
  align-self: center;
}

.sc-user-input--emoji-icon path, .sc-user-input--emoji-icon circle {
  fill: rgba(86, 88, 103, 0.3);
}

.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon circle,
.sc-user-input--emoji-icon.active path,
.sc-user-input--emoji-icon.active circle,
.sc-user-input--emoji-icon:hover path,
.sc-user-input--emoji-icon:hover circle {
  fill: rgba(86, 88, 103, 1);
}

.sc-popup-window {
  position: relative;
  width: 150px;
}

.sc-popup-window--cointainer {
  position: absolute;
  bottom: 20px;
  right: 100px;
  width: 330px;
  max-height: 260px;
  height: 260px;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
  background: white;
  border-radius: 10px;
  outline: none;
  transition: 0.2s ease-in-out;
  z-index: 1;
  padding: 0px 5px 5px 5px;
  box-sizing: border-box;
}

.sc-popup-window--cointainer.closed {
  opacity: 0;
  visibility: hidden;
  bottom: 14px;
}

.sc-popup-window--cointainer:after {
  content: "";
  width: 14px;
  height: 14px;
  background: white;
  position: absolute;
  z-index: -1;
  bottom: -6px;
  right: 28px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 2px;
}

.sc-popup-window--search {
  width: 290px;
  box-sizing: border-box;
  margin: auto;
  display: block;
  border-width: 0px 0px 1px 0px;
  color: #565867;
  padding-left: 25px;
  height: 40px;
  font-size: 14px;
  background-image: url(https://js.intercomcdn.com/images/search@2x.32fca88e.png);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 0 12px;
  outline: none;
}

.sc-popup-window--search::-webkit-input-placeholder {
  color: #C1C7CD;
}

.sc-popup-window--search::placeholder {
  color: #C1C7CD;
}
.chat
{
    list-style: none;
    margin: 0;
    padding: 0;
}

.shadow{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6) !important;
  border-right: 1px solid ; 
}

.title_border{
  border-bottom:1px solid black ; 

}
.cartoes
{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  margin-left: 1px;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-right: 8px;
  padding: 3px 10px 3px 10px;
  background-color: #FEFEFE;
}
.chat li
{
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dotted #B3A9A9;
}

.chat li.left .chat-body
{
    margin-left: 60px;
}

.chat li.right .chat-body
{
    margin-right: 60px;
}


.chat li .chat-body p
{
    margin: 0;
    color: #777777;
}

.panel .slidedown .glyphicon, .chat .glyphicon
{
    margin-right: 5px;
}

.chat-wrapper .sc-launcher
{
  right: inherit;
  z-index: 99;
}

.chat-wrapper .sc-user-input--text:empty:before {
  color: rgba(86, 88, 103, 0.8);
}

.chat-wrapper .sc-user-input.active{
  box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.8);
}

.chat-wrapper .sc-user-input--text{
  color: #3b3c42;

}
.chat-wrapper .sc-launcher .sc-open-icon{
  right: inherit;
  z-index: 99;
}
.chat-wrapper .sc-launcher .sc-closed-icon {

  right: inherit;
  z-index: 99;
}

.chat-wrapper .sc-header{
  z-index: 99;
}

.chat-wrapper .sc-message-list{
  z-index: 99;
}

.chat-wrapper .sc-user-input{
  z-index: 99;
}
.chat-wrapper .sc-chat-window{
  right: inherit;
  z-index: 99;}

.panel-body
{
    overflow-y: scroll;
    height: 250px;
}

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

.rastreamento-dialog-modal {
  width: 70%;
}

.pa-dialog-modal {
  width: 50%;
}

.mensagens-dialog-modal .modal-content {
  background-color: transparent;
  border: inherit;
  box-shadow: inherit;
}

.sc-user-input--file-icon-wrapper {
  display: none;
}
.container{
  margin-top: -10px;
}

#googleMapDiv {
  padding: 10px;
}

.autoComp > div:nth-of-type(1) {
  width: 100%;
}

.autoComp div {
  z-index: 10;
}

.autoComp div input{
  display: block;
  width: 100%;
  height: 25px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;  
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.has-error div input{
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.autoComp div input:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}

.form-control {
  height: 25px;
}

.form-control-margin2 {
  height: 25px;
  margin-top: 2px;
}

.form-group {
  margin-bottom: 4px;
}

.checkbox {
  margin-top: 25px;
  margin-bottom: 0px;
}

.checkbox input {
  margin-top: 1px;
}

.markers {
  position: absolute;
  left: -11.5px;
  top: -18.5px;
}

.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--multiple{
  border: 1px solid #ccc;
}

.has-error .select2-container--default .select2-selection--multiple{
  border-color: #a94442;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}

label {
  margin-bottom: 0;
}

button[disabled], html input[disabled] {
  cursor: not-allowed;
  background-color: #eee;
  opacity: 1;
}

.btn-toolbar {
  margin-left: 10px;
}

textarea{
  height: 55px !important;
}

.pricesButton {
  margin-top: 15px;
}

.hidden {
  display: none;
}

.enderecos-antigos {
  border-bottom: 1px solid #EBEBEB;
  padding: 15px;
}

.titulo-antigos {
	border-bottom: 1px solid;
  text-align: center;
  margin: 0;
}

.enderecos-antigos div div {
  text-align: center;
}

.enderecos-antigos:hover {
  background: #FFFFD6 !important;
}

.enderecos-antigos:focus {
  background:  rgb(224, 224, 224);
}

.react-autosuggest__suggestions-container--open {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
    background: rgba(255, 255, 255, 1) none repeat scroll 0% 0%;
    padding: 2px 0px;
    font-size: 90%;
    position: absolute;
    width: 200%;
}

.react-datepicker-popper {
  z-index: 10;
}

.search .react-autosuggest__suggestions-container--open {
  width: 95%;
}

.react-autosuggest__suggestion--highlighted {
    background: lightgray none repeat scroll 0% 0%;
}

.react-autosuggest__suggestion {
	border-bottom: 1px solid lightgrey;
}

.react-autosuggest__suggestions-list {
	padding: 0;
	list-style-type: none;
}

.agend-dialog-modal {
  width: 900px;
}

.historico-dialog-modal {
  width: 101%;
  margin: 0;
  padding: 0;
  margin-left: -10px;
}

.corridaz-dialog-modal {
  width: 100%;
  margin: 0;
  padding: 0;
}

.referencias-dialog-modal {
  width: 800px;
}

.corridaz-dialog-modal .modal-content .modal-body {
  padding: 0px !important;
}

.dados-corrida-modal {
  width: 100%;
  margin: 0;
  padding: 0;
}

.cadastros-modal {
  width: 70%;
  margin: auto;
  padding: 10;
}

.historico-despacho-modal {
  width: 70%;
}

.pesquisa-corridas-dialog-modal {
  width: 87%;
}

.box-status {
  color:#fff
}

.minha-coluna-2percent{
  width: 2% !important;
}

.minha-coluna-4percent{
  width: 4% !important;
}

.minha-coluna-6percent{
  width: 6% !important;
}

.minha-coluna-7percent{
  width: 7% !important;
}

.minha-coluna-9percent{
  width: 9% !important;
}

.minha-coluna-12percent{
  width: 12% !important;
}

.minha-coluna-18percent{
  width: 18% !important;
}

.select2-results__group{
  background: lightgray;
}

.rule-title {
	text-align: center;
	font-size: 17px;
	color: whitesmoke;
	border-bottom: 1px black solid;
	margin-top: -7px;
}

.select2-selection__choice__remove{
  display: none !important;
}

.react-table-date-picker {
  width: 7.8vw;
  border: 1px solid rgba(0,0,0,0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}
.linhatabela {
  border-bottom: 1px Solid black;
}
.pagamentoOK {
	color: #ffffff;
	font-weight: bold;
	background: #d8030b  !important;
}
.pagamentoOK:hover {
	color: #ffffff;
	ont-weight: bold;
	background: #AA4343 !important;
}
.pagamentoNOK {
	color: #000000;
	background: #CCCCCC  !important;
}
.pagamentoNOK:hover {
	color: #000000;
	background: #AAAAAA !important;
}
html {
    font-size: 14px;
  }
  .react-datepicker__time-list{
    -webkit-padding-start: 0px;
    padding-left: 0px;
  }
  .remove{
    height: 100%;
    overflow-x: hidden;
  }

  @media print {
    .col-md-3{
      margin: 45px 50px 0px 50px ;
    }
    .col-md-9{
      margin: 0px 50px 0px 41px ;
    }
  }

  .open>.dropdown-menu {
    display: block;
    width: -webkit-max-content;
    width: max-content;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
/* .block-ui-overlay {
  background-color: #e7e7e7 !important;
} */
  @font-face {
    font-family: "FontAwesome";
    src: local("FontAwesome"),
      url(/static/media/FontAwesome.b06871f2.ttf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "AntDesign";
    src: local("AntDesign"),
      url(/static/media/AntDesign.3a2ba315.ttf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Entypo";
    src: local("Entypo"),
      url(/static/media/Entypo.31b5ffea.ttf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "EvilIcons";
    src: local("EvilIcons"),
      url(/static/media/EvilIcons.140c53a7.ttf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Feather";
    src: local("Feather"),
      url(/static/media/Feather.a76d3097.ttf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Fontisto";
    src: local("Fontisto"),
      url(/static/media/Fontisto.b49ae8ab.ttf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Foundation";
    src: local("Foundation"),
      url(/static/media/Foundation.e20945d7.ttf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Ionicons";
    src: local("Ionicons"),
      url(/static/media/Ionicons.b3263095.ttf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "MaterialCommunityIcons";
    src: local("MaterialCommunityIcons"),
      url(/static/media/MaterialCommunityIcons.b62641af.ttf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "MaterialIcons";
    src: local("MaterialIcons"),
      url(/static/media/MaterialIcons.8ef52a15.ttf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Octicons";
    src: local("Octicons"),
      url(/static/media/Octicons.f7c53c47.ttf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "SimpleLineIcons";
    src: local("SimpleLineIcons"),
      url(/static/media/SimpleLineIcons.d2285965.ttf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Zocial";
    src: local("Zocial"),
      url(/static/media/Zocial.1681f34a.ttf) format("truetype");
    font-weight: normal;
  }



