  @font-face {
    font-family: "FontAwesome";
    src: local("FontAwesome"),
      url("./FontAwesome.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "AntDesign";
    src: local("AntDesign"),
      url("./AntDesign.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Entypo";
    src: local("Entypo"),
      url("./Entypo.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "EvilIcons";
    src: local("EvilIcons"),
      url("./EvilIcons.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Feather";
    src: local("Feather"),
      url("./Feather.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Fontisto";
    src: local("Fontisto"),
      url("./Fontisto.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Foundation";
    src: local("Foundation"),
      url("./Foundation.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Ionicons";
    src: local("Ionicons"),
      url("./Ionicons.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "MaterialCommunityIcons";
    src: local("MaterialCommunityIcons"),
      url("./MaterialCommunityIcons.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "MaterialIcons";
    src: local("MaterialIcons"),
      url("./MaterialIcons.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Octicons";
    src: local("Octicons"),
      url("./Octicons.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "SimpleLineIcons";
    src: local("SimpleLineIcons"),
      url("./SimpleLineIcons.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Zocial";
    src: local("Zocial"),
      url("./Zocial.ttf") format("truetype");
    font-weight: normal;
  }
